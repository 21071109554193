import type { ReactElement } from 'react';
import Head from 'next/head';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import type { MetaHeadProps } from './types';

export const HomepageMetaHead = ({ metadataContent }: MetaHeadProps): ReactElement => {
  const { locale, language, country } = useAppContext();
  const {
    configuration: { siteNameDisplay, appStoreId, appStoreName, enableUniversalLinks },
  } = useStaticContext();

  const metaTitle = metadataContent?.pageTitle || '';

  const homeUniversalMetaData = [
    { property: 'al:ios:app_name', content: appStoreName || '', key: 'meta-al:ios:app_name' },
    { property: 'al:ios:app_store_id', content: appStoreId || '', key: 'meta-al:ios:app_store_id' },
    { property: 'al:ios:url', content: 'gstar://home', key: 'meta-al:ios:url' },
  ];

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta
        name="description"
        content={metadataContent?.pageDescription || ''}
        key="meta-description"
      />
      <meta name="robots" content={metadataContent?.robots || 'index,follow'} key="meta-robots" />

      {metadataContent?.metaTags?.map(tag => {
        const attributes: { [k: string]: string } = {};

        tag.attributes?.forEach(attr => {
          if (attr.name && attr.value) {
            attributes[attr.name] = attr.value;
          }
        });

        return (
          <meta
            key={`meta-${tag.name}`}
            name={tag.name}
            property={tag.property}
            content={tag.content}
            {...attributes}
          />
        );
      })}

      <meta name="og:title" content={metaTitle} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={`${process.env.SITE_DOMAIN}/${locale}`} />
      {metadataContent?.pageDescription && (
        <meta name="og:description" content={metadataContent?.pageDescription || ''} />
      )}
      {metadataContent?.ogImageUrl && (
        <>
          <meta name="og:image" content={metadataContent?.ogImageUrl || ''} />
          <meta name="og:image:width" content="2000" />
          <meta name="og:image:height" content="2000" />
        </>
      )}
      <meta name="og:locale" content={`${language.toLowerCase()}-${country.toUpperCase()}`} />
      <meta name="og:site_name" content={siteNameDisplay || `@GstarRAW_${country.toUpperCase()}`} />

      <meta name="twitter:card" content="summary" key="meta-twitter:card" />
      <meta
        name="twitter:site"
        content={`@GstarRAW_${country.toUpperCase()}`}
        key="meta-twitter:site"
      />

      {enableUniversalLinks &&
        homeUniversalMetaData.map(m => (
          <meta property={m.property} content={m.content} key={m.key} />
        ))}

      <link rel="canonical" href={`${process.env.SITE_DOMAIN}/${locale}`} />
    </Head>
  );
};
